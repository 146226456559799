<template>
  <div class="container">
    <div class="top">
      <div class="top_mid">
        <div class="top_mid_box">
          <div class="top_mid_box_title">实体医院的互联网+医疗服务运营专家</div>
          <div class="top_mid_box_line"></div>
        </div>
      </div>
    </div>

    <!--公司简介-->
    <div class="c_intro">
      <img class="c_intro_left" src="https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/index_header_cy_icon.png">
      <div class="c_intro_right">
        <div class="c_intro_right_title"> {{c_intro.title}} </div>
        <div class="c_intro_right_text"> {{c_intro.text}} </div>
      </div>
    </div>
    <!--业务简介-->
    <div class="b_intro">
      <div class="b_intro_left">
        <div class="b_intro_left_title"> {{b_intro.title}} </div>
        <div class="b_intro_left_hint"> {{b_intro.hint}} </div>
        <div class="b_intro_left_text"> {{b_intro.text}} </div>
      </div>

      <div class="b_intro_right">
        <img class="b_intro_right_img1" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_business_1.png">
        <img class="b_intro_right_img2" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_business_2.png">
      </div>

    </div>
    <!--服务简介-->
    <div class="s_intro">
      <img class="s_intro_left" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_business_3.png">
      <div class="s_intro_right">
        <div class="s_intro_right_hint"> {{s_intro.hint}} </div>
        <div class="s_intro_right_text"> {{s_intro.text}} </div>
      </div>
    </div>
    <!--最新资讯-->
    <news></news>

  </div>






</template>

<script>
  import news from '@/components/news.vue'
export default {
  components:{
    news,
  },
  data() {
    return {
      c_intro:{
        title: '公司简介',
        text: '海南玉螺信息技术有限公司（简称玉螺互联网医疗）是海南省政府、腾讯发起入资公司，是一家专注于互联网+医疗服务建设与运营的企业。玉螺互联网医疗依托腾讯医疗健康大数据、人工智能技术生态支撑，以及自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供「医疗保障」向提供「健康服务」转型。'
      },
      b_intro:{
        title: '业务简介',
        hint: '为实体医院搭建线上线下全流程「互联网+」医疗服务平台',
        text: '玉螺互联网医疗为全国实体医院提供诊前、诊中、诊后的线上线下全流程「互联网+医疗」服务应用平台。玉螺互联网医疗通过人工智能大数据、AI 导诊、AI 影像、诊疗风险监控等医疗 AI 领先技术，提高临床决策精准度及效率，并整合医疗大数据，实现数据驱动服务，高效连接患者服务—医生服务—医院管理，满足患者的就医需求与健康管理，提高医护工作效率，提升医院效益与竞争力。'
      },
      s_intro:{
        hint: '为实体医院提供互联网医院运营服务',
        text: '互联网医院是互联网与医疗健康产业的结合，也是从提供「医疗保障」向提供「健康服务」的转型。玉螺互联网医疗除了为实体医院搭建线上线下全流程「互联网+」医疗服务平台，还将实现医院互联网化建设向「健康服务」运营的转型：依托实体医院「互联网+」医疗服务平台为流量入口，延伸院内服务至院外，如为医院提供处方流转、药房管理升级的解决方案；提供慢病健康管理全流程服务；为「互联网+护理」提供多方位、多需求、定制化管理方案等健康服务。'
      },
    }
  },
  methods: {

  },
  computed: {
  },
  created() {

  },
  mounted() {
  }
};
</script>

<style lang="stylus" scoped>
.container {
  display flex
  flex-flow column
  line-height 1
  margin 0 auto
  font-family Alibaba PuHuiTi 2.0
  font-weight normal

  .top{
    display flex
    flex-flow column
    width 100%
    height 820px
    background-size 100% 100%

    .top_mid{
      display flex
      flex-flow column
      align-items center
      justify-content center
      width 100%
      height 820px
      background url("https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/index_banner.png") no-repeat
      background-size 100% 100%
      .top_mid_box{
        display flex
        flex-flow column
        width 730px
        position absolute
        left 140px
        .top_mid_box_title{
          font-size 70px
          line-height 90px
          color #007BFF
        }

        .top_mid_box_line{
          width 250px
          height 20px
          background #007BFF
          margin-top 20px
        }
      }

    }
  }
  .c_intro{
    display flex
    justify-content space-between
    align-items center
    width 100%
    height 800px
    box-sizing border-box
    padding 0 140px
    background url("https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_intro_bg.png") no-repeat
    background-size 100% 100%
    .c_intro_left{
      width 520px
      height 200px
    }
    .c_intro_right{
      display flex
      flex-flow column
      width 536px

      .c_intro_right_title{
        font-size 60px
        line-height 84px
        color #343A40
      }
      .c_intro_right_text{
        font-size 20px
        line-height 34px
        color #6C757D
        margin-top 24px
      }
    }

  }

  .b_intro{
    display flex
    box-sizing border-box
    justify-content space-between
    align-items center
    padding 140px
    .b_intro_left{
      display flex
      flex-flow column
      width 674px

      .b_intro_left_title{
        font-size 60px
        line-height 84px
        color #343A40
      }
      .b_intro_left_hint{
        font-size 32px
        line-height 45px
        color #343A40
        margin-top 60px
      }
      .b_intro_left_text{
        margin-top 32px
        font-size 20px
        line-height 34px
        color #6C757D
      }
    }
    .b_intro_right{
      display flex

      .b_intro_right_img1{
        width 317px
        height 800px
      }
      .b_intro_right_img2{
        width 317px
        height 800px
        /*margin 38px 0 0 px*/
        margin 76px 0 0 68px
      }
    }
  }
  .s_intro{
    display flex
    box-sizing border-box
    justify-content space-between
    align-items center
    padding 80px 140px 200px 140px

    .s_intro_left{
      width 952px
      height 700px
      margin-left -140px
    }

    .s_intro_right{
      width 674px
      display flex
      flex-flow column
      .s_intro_right_hint{
        font-size 32px
        line-height 45px
        color #343A40
      }
      .s_intro_right_text{
        font-size 20px
        line-height 34px
        color #6C757D
        margin-top 74px
      }
    }
  }
}
</style>
