<template>
  <div class="bottom">
    <div class="bottom_item">
      <div class="bottom_item_left">
        <img
            class="left_pic"
            src="https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/index_footer_new_icon.png"
        />
        <div class="left_item">
          <div class="left_item_title">联系我们</div>
          <div class="contact_item" v-for="item in infoList" :key="item">
            <img class="contact_item_icon" :src="item.icon" />
            <div class="contact_item_text">
              <div class="text_1"> {{ item.text }} </div>
              <div class="text_2"> {{item.text_2}} </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom_item_right">
        <div class="right_title">合作伙伴</div>
        <img class="right_img" src="https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/index_footer_company.png">
      </div>
      <img
        class="bottom_item_right"

      />

    </div>
    <div class="bottom_info">
      <div class="bottom_info_left">Copyright © 2014 - 2021 WESHELL TECHNOLOGY. All Rights Reserved. 海南玉螺信息技术有限公司 版权所有</div>
      <div class="bottom_info_right">
        琼ICP备15001719号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
  data() {
    return {
      infoList: [
        {
          icon: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_bottom_icon_tel.png",
          text: "0898-6675 5633"
        },
        {
          icon: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_bottom_icon_mobile.png",
          text: "189 7600 6588"
        },
        {
          icon: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_bottom_icon_email.png",
          text: "marketing@yuluoo.com"
        },
        {
          icon: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_index_bottom_icon_addr.png",
          text: "海南省海口市美兰区海府街道",
          text_2: "海南数据谷二号营地2层219-221室"
        }
      ]
    };
  }
};
</script>

<style lang="stylus" scoped>
.bottom{
  display flex
  flex-flow column
  box-sizing border-box
  padding 60px 140px 30px 140px
  background #F5F7FA
  font-family Alibaba PuHuiTi 2.0
  font-weight normal
  .bottom_item{
    display flex
    justify-content space-between

    .bottom_item_left{
      display flex
      flex-flow column
      width 50%
      box-sizing border-box

      .left_title{
        font-size 20px
        font-weight 500
        line-height 29px
        color #343A40
        margin-bottom 17px
      }
      .left_pic{
        width 300px
        height 120px
      }

      .left_item{
        display flex
        flex-flow column
        margin-left 112px
        .left_item_title{
          font-size 20px
          line-height 28px
          color #343A40
          margin 12px 0 16px 0
        }
        .right_item_title{
          font-size 20px
          font-weight 500
          line-height 29px
          color #343A40
          margin-top 68px
        }
        .contact_item{
          display flex
          margin-top 12px

          .contact_item_icon{
            width 20px
            height 20px
          }

          .contact_item_text{
            display flex
            flex-flow column
            font-size 16px
            font-weight 400
            line-height 24px
            color #777777
            margin-left 10px
            width 255px
          }
        }

      }
    }

    .bottom_item_right{
      display flex
      flex-flow column
      margin-top 120px
      .right_title{
        font-size 20px
        line-height 28px
        color #343A40
        margin-bottom 16px
      }
      .right_img{
        width 630px
        height 188px
      }
    }
  }

  .bottom_info{
    display flex
    justify-content space-between
    margin-top 45px
    font-size 16px
    font-weight 400
    line-height 24px
    color #818A91

    .bottom_info_right{
      box-sizing border-box
      padding-left 88px
    }
  }
}
</style>
