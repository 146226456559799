<template>
  <my-header></my-header>
  <router-view />

  <my-footer></my-footer>
</template>

<script>
  import MyHeader from '@/components/MyHeader.vue'
  import MyFooter from '@/components/MyFooter.vue'

export default {
    components: {
      MyHeader,
      MyFooter
    }

}

</script>

<style>


body {
  margin: 0;
  padding: 0;
}
</style>
