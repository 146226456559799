<template>
  <div class="header">
    <img
      class="header_icon"
      src="https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/index_header_new_icon.png"
      @click="toIndex"
    />
    <div class="top_nav_item">
      <div class="top_nav_item_content" v-for="(item, index) in navList" :key="item.id" @click="toNav(index)">
        <div class="content_1"  :class="selectValue == index ? 'isActive' : ''" @mouseover="mouseover(item, index)">{{item.content}} </div>
        <div class="content_2" v-if="showId == item.id" >
          <div class="content_2_item" v-for="(item_2, index_2) in item.title" :key="item_2.id"
               :class="selectValue_2 == index_2 ? 'isActive_2': ''"
               @mouseover="mouseover_2(index_2)"
               @click.stop="toNav_2(item_2)"> {{item_2.classify}}  </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MyHeader",

  watch:{
    selectValue(val){
      console.log('val:' , val)
    }
  },
  data(){
    return{
      showId: null,
      navList:[
        {
          id: 1,
          content: '首页',
        },
        {
          id: 2,
          content: '产品服务',
          title: [
            {classify: '「互联网+」医疗服务平台', titleId: 2},
            {classify: '处方流转', titleId: 3},
            {classify: '「互联网+」护理', titleId: 4},
            {classify: '健康管理', titleId: 5},
          ]
        },
        {
          id: 3,
          content: '经典案例',
          title: [
            {classify: '海南省人民医院', titleId: 6},
            {classify: '海口市中医院', titleId: 7},
            {classify: '海南省第二人民医院', titleId: 8},
          ]
        },
        {
          id: 4,
          content: '城市合伙人',
        },
        {
          id: 5,
          content: '加入我们',
          // title: [
          //   {classify: '社会招聘', titleId: 9},
          //   {classify: '校园招聘', titleId: 10} ,
          // ]
        }
      ],
      selectValue: null,
      selectValue_2: null,
    }
  },
  methods:{
    mouseover(item, index){
      this.showId = item.id
      // this.selectValue = index
    },
    mouseover_2(index){
      this.selectValue_2 = index
    },
    toIndex(){
      this.$router.push({ path: '/' })
    },
    toNav(index){
      this.selectValue = index
      switch (index) {
        case 0:
          this.$router.push({ path: '/' })
          break;
        case 1:
          this.$router.push({ path: '/product' })
          break;
        case 2:
          this.$router.push({ path: '/case' })
          break;
        case 3:
          this.$router.push({ path: '/partner'})
          break;
        case 4:
          this.$router.push({ path: '/job' })
          break;
      }
    },

    toNav_2(item_2){
      switch (item_2.titleId) {
        case 2:
          this.$router.push({ path: '/product_ylfw' });
          break;
        case 3:
          this.$router.push({ path: '/product_cflz' });
          break;
        case 4:
          this.$router.push({ path: '/product_hlwjhl' });
          break;
        case 5:
          this.$router.push({ path: '/product_jkgl' });
          break;
        case 6:
          this.$router.push({ path: '/case_detail' });
          break;
        case 7:
          this.$router.push({ path: '/case_detail_3' });
          break;
        case 8:
          this.$router.push({ path: '/case_detail_2' });
          break;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.header{
  display flex
  justify-content space-between
  border-bottom 1px solid #E4E7ED
  box-sizing border-box
  padding 0 140px

  .header_icon{
    width 203px
    height 120px
  }
  .top_nav_item{
    display flex
    width 60%
    justify-content space-between
    align-items center
    .top_nav_item_content{
      display flex
      flex-flow column
      font-size 20px
      line-height 28px
      width 25%
      text-align center
      position relative

      .content_1{
        cursor pointer
      }
      .isActive{
        font-weight bold
        position relative
        z-index 111
      }
      .isActive:before{
        content ''
        position absolute
        margin 0 auto
        left 0
        right 0
        bottom -10px
        width 78px
        height 6px
        background #007BFF

      }
      .content_2{
        color #B0B6BA
        margin-top 48px
        position absolute
        left -26px
        right 0
        cursor pointer
        background #fff
        border-radius 8px
        width 260px
        z-index 1
        .content_2_item{
          text-align center
          margin-bottom 26px
        }
        .content_2_item:first-child{
          margin-top 16px
        }
        .isActive_2{
          color #007bff
          font-weight bold
        }
      }
    }
  }
}
</style>
