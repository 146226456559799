import { createRouter, createWebHashHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Index from "../views/index";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import("../views/news.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import("../views/newsDetail.vue"),
    meta:{
    }
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import("../views/product"),
    meta:{
    }
  },
  {
    path: "/product_ylfw",
    name: "product_ylfw",
    component: () =>
      import("../views/product_ylfw"),
    meta:{
    }
  },
  {
    path: "/product_cflz",
    name: "product_cflz",
    component: () =>
      import("../views/product_cflz"),
    meta:{
    }
  },
  {
    path: "/product_hlwjhl",
    name: "product_hlwjhl",
    component: () =>
      import("../views/product_hlwjhl"),
    meta:{
    }
  },
  {
    path: "/product_jkgl",
    name: "product_jkgl",
    component: () =>
      import("../views/product_jkgl"),
    meta:{
    }
  },
  {
    path: "/case",
    name: "case",
    component: () =>
      import("../views/case"),
    meta:{
    }
  },
  {
    path: "/case_detail",
    name: "case_detail",
    component: () =>
      import("../views/case_detail"),
    meta:{
    }
  },
  {
    path: "/case_detail_2",
    name: "case_detail_2",
    component: () =>
      import("../views/case_detail_2"),
    meta:{
    }
  },
  {
    path: "/case_detail_3",
    name: "case_detail_3",
    component: () =>
      import("../views/case_detail_3"),
    meta:{
    }
  },
  {
    path: "/partner",
    name: "partner",
    component: () =>
      import("../views/partner"),
    meta:{
    }
  },
  {
    path: "/partner_join",
    name: "partner_join",
    component: () =>
      import("../views/partner_join"),
    meta:{
    }
  },
  {
    path: "/job",
    name: "job",
    component: () =>
      import("../views/job"),
    meta:{
    }
  },
  {
    path: "/job_detail",
    name: "job_detail",
    component: () =>
      import("../views/job_detail"),
    meta:{
    }
  },
  {
    path: "/admin_news",
    name: "admin_news",
    component: () =>
      import("../views/admin_news"),
    meta:{
    }
  },


  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
